<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">Users</h4>
                            </div>
<!--                            <div class="col-md-10 text-right">-->
<!--                                <b-button v-b-modal.modal-center variant="success">-->
<!--                                    <i class="ri-add-line  align-middle mr-2"></i> Add an offer-->
<!--                                </b-button>-->
<!--                                <b-modal-->
<!--                                        id="modal-center"-->
<!--                                        centered-->
<!--                                        title="Add Offer"-->
<!--                                        size="lg"-->
<!--                                        title-class="font-18"-->
<!--                                        ref="my-modal"-->
<!--                                        hide-footer-->

<!--                                >-->
<!--                                    <form @submit.prevent="createOffer">-->
<!--                                        <div class="row">-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >Name</label>-->
<!--                                                <input-->
<!--                                                        type="text"-->
<!--                                                        v-model="name"-->
<!--                                                        class="form-control"-->
<!--                                                        placeholder="Enter name"-->
<!--                                                />-->
<!--                                            </div>-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >frequency</label>-->
<!--                                                <input-->
<!--                                                        type="text"-->
<!--                                                        v-model="frequency"-->
<!--                                                        class="form-control"-->
<!--                                                        placeholder="Enter frequency"-->
<!--                                                />-->
<!--                                            </div>-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >Data Amount</label>-->
<!--                                                <input-->
<!--                                                        type="text"-->
<!--                                                        v-model="data_amount"-->
<!--                                                        class="form-control"-->
<!--                                                        placeholder="Data Amount"-->
<!--                                                />-->
<!--                                            </div>-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >Price</label>-->
<!--                                                <input-->
<!--                                                        type="number"-->
<!--                                                        v-model="price"-->
<!--                                                        class="form-control"-->
<!--                                                        placeholder="Price"-->
<!--                                                />-->
<!--                                            </div>-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >Rationed</label>-->
<!--                                                <input-->
<!--                                                        type="text"-->
<!--                                                        v-model="rationed"-->
<!--                                                        class="form-control"-->
<!--                                                        placeholder="Rationed"-->
<!--                                                />-->
<!--                                            </div>-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >Data only</label>-->
<!--                                                <input-->
<!--                                                        type="text"-->
<!--                                                        v-model="data_only"-->
<!--                                                        class="form-control"-->
<!--                                                        placeholder="Data only"-->
<!--                                                />-->
<!--                                            </div>-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >Category</label>-->
<!--                                                <multiselect v-model="cat" :options="categoryName" :multiple="false" placeholder=" Select Category"></multiselect>-->
<!--                                            </div>-->
<!--                                            <div class="col-md-6">-->
<!--                                                <label >Description</label>-->
<!--                                                <textarea-->
<!--                                                        v-model="descrip"-->
<!--                                                        class="form-control"-->
<!--                                                        :maxlength="225"-->
<!--                                                        rows="1"-->
<!--                                                        placeholder="Comment"-->
<!--                                                ></textarea>-->
<!--                                            </div>-->

<!--                                        </div>-->
<!--                                        <button-->
<!--                                                class="btn btn-primary w-md waves-effect waves-light mt-4"-->
<!--                                                type="submit"-->
<!--                                                @click="hideModal"-->
<!--                                        >Save</button>-->
<!--                                    </form>-->

<!--                                </b-modal>-->
<!--                            </div>-->

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="userData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(actions)="{item}">
                                    <!--                                    <div class="row justify-content-end">-->
<!--                                    <b-button v-b-tooltip.hover title="Edit" class="actionBut" @click="update(item)" variant="success">-->
<!--                                        <i class="ri-pencil-fill align-middle "></i>-->
<!--                                    </b-button>-->
                                    <b-button v-b-tooltip.hover title="Delete" class="actionBut" @click="del(item)" variant="danger">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>

                                    <!--                                    </div>-->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import firebase from "../../../firebaseInit";
    const db = firebase.firestore();

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "Fixed Offer",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                categoryData: [],
                categoryName: null,
                userData: [],
                rationed: "",
                price:"",
                name:"",
                frequency:"",
                data_only: "",
                data_amount:"",
                descrip:"",
                cat:"",
                tableData: "",
                title: "View User",
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "Users",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "id", sortable: true },
                    { key: "phone", sortable: true },

                ],
                value:null,


            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.userData.length;
            }
        },
        mounted(){
            // Set the initial number of items
            this.totalRows = this.items.length;
            db.collection("Users")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.userData.push({
                            id: doc.id,
                            phone: doc.data().phone,
                            token: doc.data().token,

                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                    console.log(this.userData)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        },
        methods: {
            del(e) {
                const  index = this.userData.indexOf(e)
                console.log(e.id)
                db.collection("fixed_offers")
                    .doc(e.id)
                    .delete()
                    .then(() => {
                        this.userData.splice(index,1)
                        console.log("Offer successfully deleted!");

                    })
                    .catch((error) => {
                        console.error("Error removing document: ", error);
                    });
            },
            update(e){
                console.log(e)
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left:15px;
    }
</style>